/**
 * Please keep all constants in alphabetical order, including keys in the objects, as
 * best as possible
 */
import {getDomain} from './server-environment';
import {IS_DESKTOP} from '@aoflmkt/is-desktop';
import Cookies from 'js-cookie';

const urlSearchParams = new URLSearchParams(window.location.search);

const SECURE = location.protocol === 'https:';
const DOMAIN = getDomain();
const UA = navigator.userAgent;

const adjustTokens = {
  MINI_REG: 'aqjwyu',
  SUBSCRIPTION: 'jncw63',
  REACTIVATION: 'kuc5k3',
  SIGNIN: '3gd52j'
};

const apis = {
  READINGIQ_MARKETING: 'readingIqMarketing'
};

const apiFormatters = {
  READINGIQ_JSON: 'readingIqJson'
};

const appCalls = {
  LOG_BI_EVENT: 'webview://logEvent',
  ADJUST_EVENT: 'webview://adjustEvent',
  ROUTE_TO_APP: 'session://routeToApp',
  PURCHASE: 'webview://inAppPurchase',
  LOGIN: 'session://login',
  NAVIGATE: 'location://navigate',
  RESTORE: 'webview://restoreTransactions'
};

const appCallCallbacks = {
  ADJUST_EVENT: 'adjustEventCallback',
  RESTORE_SUCCESS: 'restoreTransactionsSuccessCallback',
  RESTORE_FAILURE: 'restoreTransactionsFailureCallback',
  IAP_SUCCESS: 'iapSuccessCallback',
  IAP_FAILURE: 'iapFailureCallback'
};

const cacheNamespaces = {
  API_AUTH: 'academy-auth',
  CAMPAIGN_PIXELS_LOG: 'campaign-pixels-log',
  REACTIVATION_USER_DATA: 'reactivation-user-data',
  ITUNES_RECEIPT: 'itunesReceiptData',
  USER_SESSION: 'user-session',
  ROTATION_VERSION: 'rotation-version'
};

const cookies = {
  CAMPAIGN_INFO: 'campaign_info',
  REDEEM_CODE: 'c13367945d',
  APP_SESSION_ID: 'appSessionId',
  APP_VERSION: 'appVersion',
  COUNTRY_CODE: 'countryCode',
  REGION: 'region',
  TOKEN: 'token',
  CONFIG: {
    secure: SECURE,
    domain: DOMAIN,
    expires: 365
  },
  DEVICE_ID: 'deviceId',
  ELOG: 'elog_email',
  LINK_FROM_APP: 'link_from_app',
  PLATFORM_NAME: 'platformName',
  RESET_PASSWORD: '272f199969',
  EMAIL_UNSUBSCRIBE: 'f0489350d4',
  GDPR: 'gdpr',
  CJ_COOKIE: 'cje',
  CJ_CONFIG: {
    secure: SECURE,
    domain: DOMAIN,
    expires: 35
  }
};

const createAccountFormTypes = {
  SCHOOL_CLOSURE: 'a86cd3e'
};

const linkFromAppParam = urlSearchParams.get('linkFromApp');

const environment = {
  PUBLIC_PATH: __webpack_public_path__,
  get IS_LINK_FROM_APP() {
    return Cookies.get('link_from_app') === 'android' || linkFromAppParam;
  },
  get IN_APP() {
    return Cookies.get(cookies.APP_VERSION);
  },
  get FLUTTER_AMAZON() {
    return Cookies.get(cookies.PLATFORM_NAME) === 'FLUTTER_AMAZON' || (UA.indexOf('FLUTTER_AMAZON') !== -1);
  },
  get FLUTTER_IOS() {
    return Cookies.get(cookies.PLATFORM_NAME) === 'FLUTTER_IOS' || (UA.indexOf('FLUTTER_IOS') !== -1);
  },
  get FLUTTER_ANDROID() {
    return Cookies.get(cookies.PLATFORM_NAME) === 'FLUTTER_ANDROID' || (UA.indexOf('FLUTTER_ANDROID') !== -1);
  },
  get UNITY_AMAZON() {
    return Cookies.get(cookies.PLATFORM_NAME) === 'UNITY_AMAZON' || (UA.indexOf('UNITY_AMAZON') !== -1);
  },
  get UNITY_IOS() {
    return Cookies.get(cookies.PLATFORM_NAME) === 'UNITY_IOS' || (UA.indexOf('UNITY_IOS') !== -1);
  },
  get UNITY_ANDROID() {
    return Cookies.get(cookies.PLATFORM_NAME) === 'UNITY_ANDROID' || (UA.indexOf('UNITY_ANDROID') !== -1);
  },
  IS_DESKTOP,
  PLATFORM: 'marketing',
  SECURE,
  DOMAIN
};

const eventNames = {
  VIDEO_PROGRESS: 'AOFL.READINGIQ_MARKETING.V1.video-progress'
};

const redirectCountryCodes = {
  PNG: 'PG' // Papua New Guinea
};

const countryCodes = {
  AT: 'Austria',
  AU: 'Australia',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CY: 'Republic of Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GR: 'Greece',
  HU: 'Hungary',
  HR: 'Croatia',
  IE: 'Ireland',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SK: 'Slovakia',
  SI: 'Slovenia'
};

const localStorageKeys = {
  APP_SESSION_ID: 'readingIqAppSessionId',
  TOKEN: 'readingIqToken',
  DEVICE_ID: 'readingIqDeviceId',
  DEBUG: 'debug',
  REDEEM_CODE_INFO: 'redeemCodeInfo'
};

const newWorlds = {
  PROMO_CODE: 'FRI2021'
};

const cookieConsentKeys = {
  COOKIE_PREFERENCES: 'cookiePreferences'
};

const cookieConsentValues = {
  COOKIES_DEFAULT: 'C0002',
  COOKIES_OPTOUT: 'C0003',
};

const queryParamKeys = {
  APP_SESSION_ID: 'appSessionId',
  APP_VERSION: 'appVersion',
  CJ_EVENT: 'cjevent',
  COUNTRY_CODE: 'country',
  DEVICE_ID: 'deviceId',
  EMAIL: 'email',
  LANDING_PIXEL: 'landingPixel',
  LINK_FROM_APP: 'linkFromApp',
  PLATFORM_NAME: 'platformName',
  TOKEN: 'token',
  SOURCE_TAG: 'src_tag',
  REDEEM_CODE: 'code',
  ROTATION_VERSION: 'rotationVersion',
  DNSMPI: 'dnsmpi',
};

const queryParamValues = {
  DNSMPI: '1'
};

const outboundLinks = {
  PRIVACY_PORTAL: 'https://privacyportal-cdn.onetrust.com/dsarwebform/a2692794-047c-4c18-b3bf-60d783ebc844/8205df50-001c-4e16-b5f9-d4e945bc612e.html',
  AOFL_ACCESSIBILITY: 'https://www.ageoflearning.com/readingiq/accessibility/',
  CCPA: 'https://www.ageoflearning.com/ccpa/',
  TANDC: 'https://www.ageoflearning.com/riq-tandc/',
  TANDC_INTL: 'https://www.ageoflearning.com/privacy-policies/',
  PRIVACY_POLICY: 'https://www.ageoflearning.com/riq-privacy-current/'
};

const regStepsEnumerate = {
  COMPLETE: 'REGPATH_LAST_STEP',
  REACTIVATE: 'REGPATH_REACTIVATION',
  SCHOOL: 'REGPATH_SCHOOL_SUBSCRIPTION',
  SCHOOL_UPGRADE: 'REGPATH_SCHOOL_UPGRADE',
  UPGRADE: 'REGPATH_UPGRADE',
  UPGRADE_1AS5S7F: 'REGPATH_UPGRADE_1AS5S7F',
  UPGRADE_04C6921: 'REGPATH_UPGRADE_04C6921',
  UPGRADE_AECC093: 'REGPATH_UPGRADE_500_F2M'
};

const redeemCodeTypes = {
  DEMO: 'demo',
  DISCOUNT: 'discount',
  EMS: 'ems',
  PAID_TEACHER: 'paid_teacher',
  FREE_SCHOOL: 'free_school',
  ENTERPRISE_TEACHER: 'enterprise_teacher',
  ENTERPRISE_PARENT: 'enterprise_parent'
};

const rotation = {
  READINGIQ: 'readingIqMarketing'
};

const WEBAPP = '/web/learning/';
const FLUTTERWEB = '/web/m/#app_startup/';

const pages = {
  [regStepsEnumerate.COMPLETE]: WEBAPP,
  [regStepsEnumerate.REACTIVATE]: environment.PUBLIC_PATH + 'reactivation/',
  [regStepsEnumerate.SCHOOL]: environment.PUBLIC_PATH + 'subscription/teacher',
  [regStepsEnumerate.SCHOOL_UPGRADE]: environment.PUBLIC_PATH + 'upgrade/',
  [regStepsEnumerate.UPGRADE]: environment.PUBLIC_PATH + 'upgrade/',
  [regStepsEnumerate.UPGRADE_1AS5S7F]: environment.PUBLIC_PATH + 'upgrade/offer/1as5s7f',
  [regStepsEnumerate.UPGRADE_04C6921]: environment.PUBLIC_PATH + 'upgrade/offer/04c6921',
  [regStepsEnumerate.UPGRADE_AECC093]: environment.PUBLIC_PATH + 'upgrade/offer/aecc093',
  FLUTTERWEB,
  ACCESSIBILITY: environment.PUBLIC_PATH + 'accessibility/',
  ACCESSIBILITY_LINK_FROM_APP_ANDROID: environment.PUBLIC_PATH + 'accessibility/?linkFromApp=android',
  CDIV: environment.PUBLIC_PATH + '404/',
  CR_FAQS: environment.PUBLIC_PATH + 'cr-faqs',
  CUSTOMER_SUPPORT: environment.PUBLIC_PATH + 'customer-support',
  DEVICE: environment.PUBLIC_PATH + 'device',
  EMAIL_UNSUBSCRIBE: environment.PUBLIC_PATH + 'email-unsubscribe',
  HOME: environment.PUBLIC_PATH,
  LOGIN: environment.PUBLIC_PATH + 'login',
  REGISTER_IOS: environment.PUBLIC_PATH + 'register-ios',
  REGISTER_IOS_REACTIVATION: environment.PUBLIC_PATH + 'register-ios/reactivation',
  TEACHERS_REGISTRATION: '/teachers/registration',
  TEACHERS: '/teachers',
  WELCOME_TEACHERS: '/teachers/welcome',
  DOWNLOAD_APP: environment.PUBLIC_PATH + 'download-app',
  PRIVACY_POLICY: outboundLinks.PRIVACY_POLICY,
  PRIVACY_POLICY_LINK_FROM_APP_ANDROID: environment.PUBLIC_PATH + 'privacy-policy/?linkFromApp=android',
  PRIVACY_POLICY_PREVIOUS: environment.PUBLIC_PATH + 'privacy-policy/previous',
  PRIVACY_POLICY_PREVIOUS_LINK_FROM_APP_ANDROID: environment.PUBLIC_PATH + 'privacy-policy/previous/?linkFromApp=android',
  REACTIVATION: environment.PUBLIC_PATH + 'reactivation',
  REDEEM: environment.PUBLIC_PATH + 'redeem',
  REDEEM_CREATE_ACCOUNT: environment.PUBLIC_PATH + 'redeem/create-account',
  REDEEM_CONNECT_MOBILE: '/teachers/redeem/how-to',
  REDEEM_CONNECT_WEB: WEBAPP + '#/settings/link-to-classroom',
  REDEEM_CONNECT_WEB_VALIDATED: WEBAPP + '#/settings/select-child',
  REDEEM_NEW_ACCOUNT: '/teachers/redeem/create-account',
  REDEEM_UPGRADE: environment.PUBLIC_PATH + 'subscription/teacher',
  SUBSCRIPTION: environment.PUBLIC_PATH + 'subscription',
  SUBSCRIPTION_ANNUAL: environment.PUBLIC_PATH + 'subscription-annual',
  SUBSCRIPTION_OFFER: environment.PUBLIC_PATH + 'subscription/offer',
  SUBSCRIPTION_IOS: environment.PUBLIC_PATH + 'subscription-ios',
  SUBSCRIPTION_1AS5S7F: environment.PUBLIC_PATH + 'subscription/offer/1as5s7f',
  SUBSCRIPTION_CLASSROOM_REWARDS_7E47B3E: environment.PUBLIC_PATH + 'subscription/offer/7e47b3e',
  SUBSCRIPTION_ABCMOUSE_743959D: environment.PUBLIC_PATH + 'subscription/offer/743959d',
  TANDC: outboundLinks.TANDC,
  TANDC_LINK_FROM_APP_ANDROID: outboundLinks.TANDC + '?linkFromApp=android',
  FORGOT_PASSWORD: environment.PUBLIC_PATH + 'forgot-password'
};

const paymentTypes = {
  CREDIT_CARD: 'PAYMENTECH',
  ITUNES: 'ITUNES',
  AMAZON: 'AMAZON'
};

const pixelEvents = {
  CONVERSION: 'conversion',
  LANDING: 'landing',
  PROSPECT_REGISTRATION: 'prospect_registration'
};

const privacyPolicySections = {
  CALIFORNIA_PRIVACY_RIGHTS: pages.PRIVACY_POLICY + '?scrollTo=section-7',
  CALIFORNIA_PRIVACY_RIGHTS_PREVIOUS: pages.PRIVACY_POLICY_PREVIOUS + '?scrollTo=section-8',
  CALIFORNIA_PRIVACY_RIGHTS_LINK_FROM_APP_ANDROID: pages.PRIVACY_POLICY + '?linkFromApp=android&scrollTo=section-7',
  CALIFORNIA_PRIVACY_RIGHTS_PREVIOUS_LINK_FROM_APP_ANDROID: pages.PRIVACY_POLICY_PREVIOUS + '?linkFromApp=android&scrollTo=section-8',
};

const products = {
  MONTHLY_TRIAL_599: 'e4d30adc-bed9-49d1-abae-66ea1e216a22',
  MONTHLY_TRIAL_799: 'b9e022c1-8bc4-461c-938a-a9e0839019b4',
  MONTHLY_TRIAL_IOS_799: '8b8b35f0-48b8-4bb7-9cce-b5f42d7aaaa8',
  MONTHLY_GROUPON_TRIAL_799: 'b1d6a764-e56e-4bf0-9f30-576b1cba7bb1',
  ANNUAL_IOS_3999: '5545e240-dfe0-4909-8a66-cc56b95f0da6',
  ANNUAL_2499: 'f2afe3e6-5089-4b47-bace-e0918c4491c3',
  ANNUAL_2999: 'eba4a332-76b9-49a6-8bf2-c0a006613bd3',
  ANNUAL_3999: '8a3f183e-bca9-4fc2-8e4c-116428dd16b6',
  ANNUAL_4999: 'f275a63e-7c79-42cc-8e97-808ad9507031',
  ANNUAL_IOS_REACTIVATION_3999: 'c483590c-de9c-456b-ae67-e9479b5f92b4',
  ANNUAL_SEMI_1499: '22dab35e-1b3b-415e-a9f3-b9a4cfe22371',
  ANNUAL_SEMI_1999: '17cdade8-256e-4a04-9027-7dc7acdcde0a',
  ANNUAL_SEMI_2499: '7cc4ba94-631f-46a9-955c-8c2795927278',
  MONTHLY_NO_TRIAL_599: 'e283bd58-6c39-4770-8a0a-0330d922854e',
  MONTHLY_NO_TRIAL_799: '4ffc0e21-1667-4f5a-a22c-19ac18229881',
  MONTHLY_IOS_NO_TRIAL_799: '7593cd72-aa2e-4477-a8f0-a66fa3b9c0e9',
  SEASONAL_5_FOR_2: 'e093e75a-f94a-4efe-b6ee-5afed191033a',
  ABCMOUSE_OFFER_TRIAL_1200: '5fddb64e-e090-42c6-85d5-674aafe64337',
  MONTHLY_TRIAL_799_CIT_MIT: '8fe9a2e9-02b5-41c0-ad4d-18be9d26e1e3',
  ANNUAL_3999_CIT_MIT: '96661373-784b-4360-a66f-66c53d564aae',
  ANNUAL_SEMI_1999_CIT_MIT: '1d7138b8-5d4c-4912-9e18-67f3de9f1e22',
};

const sdoNamespaces = {
  BANNER_BACKGROUND: 'bannerBackground',
  BANNER_MINI_REG: 'bannerMiniReg',
  CUSTOMER_SUPPORT: 'customerSupport',
  EMAIL_PREFERENCES: 'emailPreferences',
  FORGOT_PASSWORD: 'forgotPassword',
  HOME_TABS: 'homeTabs',
  BANNER_REG_FORM: 'bannerRegForm',
  GDPR_BANNER: 'gdprBanner',
  LOGIN: 'login',
  MAIN_LAYOUT: 'mainLayout',
  MODALS: 'modals',
  SLIDE_CONTENTS: 'slideContents',
  PARENT_GATE: 'parentGate',
  PROSPECT_REG_FORM: 'prospectRegForm',
  REACTIVATION: 'reactivation',
  REACTIVATION_IOS: 'reactivationIos',
  READINGIQ_PAYMENT_FORM: 'paymentForm',
  READINGIQ_REGISTER_FORM: 'registerForm',
  REGISTER_FORM: 'registerForm',
  REGISTER_IOS: 'registerIos',
  REGISTER_IOS_REACTIVATION: 'registerIosReactivation',
  REGISTER_IOS_ACTIVE_USER_MODAL: 'registerIosActiveUserModal',
  REGISTER_IOS_DEACTIVATED_USER_MODAL: 'registerIosDeactivatedUserModal',
  SESSION: 'session',
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_CLASSROOM_REWARDS_7E47B3E: 'subscriptionClassroomRewards7e47b3e',
  SUBSCRIPTION_CLASSROOM_REWARDS_7E47B3E_ACTIVE_USER_MODAL: 'subscriptionClassroomRewards7e47b3eActiveUserModal',
  SUBSCRIPTION_CLASSROOM_REWARDS_7E47B3E_INVALID_DEACTIVATED_USER_MODAL: 'subscriptionClassroomRewards7e47b3eInvalidDeactivedUserModal',
  SUBSCRIPTION_CLASSROOM_REWARDS_7E47B3E_VALID_DEACTIVATED_USER_MODAL: 'subscriptionClassroomRewards7e47b3eValidDeactivedUserModal',
  SUBSCRIPTION_CLASSROOM_REWARDS_7E47B3E_ERROR_MODAL: 'subscriptionClassroomRewardsTeacher7e47b3eErrorModal',
  SUBSCRIPTION_GROUPON_OFFER_04C6921: 'subscriptionGrouponOffer04c6921',
  SUBSCRIPTION_GROUPON_OFFER_04C6921_ACTIVE_USER_MODAL: 'subscriptionGrouponOffer04c6921ActiveUserModal',
  SUBSCRIPTION_GROUPON_OFFER_04C6921_ERROR_MODAL: 'subscriptionGrouponOffer04c6921ErrorModal',
  SUBSCRIPTION_GROUPON_OFFER_04C6921_VALID_DEACTIVATED_MODAL: 'subscriptionGrouponOffer04c6921ValidDeactivedUserModal',
  SUBSCRIPTION_TEACHER_OFFER_1AS5S7F: 'subscriptionTeacherOffer1as5s7f',
  SUBSCRIPTION_TEACHER_OFFER_1AS5S7F_ACTIVE_USER_MODAL: 'subscriptionTeacherOffer1as5s7fActiveUserModal',
  SUBSCRIPTION_TEACHER_OFFER_1AS5S7F_INVALID_DEACTIVATED_USER_MODAL: 'subscriptionTeacherOffer1as5s7fInvalidDeactivedUserModal',
  SUBSCRIPTION_TEACHER_OFFER_1AS5S7F_VALID_DEACTIVATED_USER_MODAL: 'subscriptionTeacherOffer1as5s7fValidDeactivedUserModal',
  SUBSCRIPTION_TEACHER_OFFER_1AS5S7F_ERROR_MODAL: 'subscriptionTeacherOffer1as5s7fErrorModal',
  SUBSCRIPTION_ANNUAL: 'subscriptionAnnual',
  SUBSCRIPTION_ANNUAL_ACTIVE_USER_MODAL: 'subscriptionAnnualActiveUserModal',
  SUBSCRIPTION_ANNUAL_ERROR_MODAL: 'subscriptionAnnualErrorModal',
  SUBSCRIPTION_ACTIVE_USER_MODAL: 'subscriptionActiveUserModal',
  SUBSCRIPTION_IOS: 'subscriptionIos',
  SUBSCRIPTION_IOS_ACTIVE_USER_MODAL: 'subscriptionIosActiveUserModal',
  SUBSCRIPTION_IOS_WELCOME_BACK_MODAL: 'subscriptionIosWelcomeBackModal',
  SUBSCRIPTION_WELCOME_BACK_MODAL: 'subscriptionWelcomeBackModal',
  SUBSCRIPTION_FREE_ACCOUNT: 'subscriptionFreeAccount',
  SUBSCRIPTION_FREE_ACCOUNT_ERROR_MODAL: 'subscriptionFreeAccountErrorModal',
  SUBSCRIPTION_FREE_ACCOUNT_IOS: 'subscriptionFreeAccountIos',
  SUBSCRIPTION_FREE_ACCOUNT_IOS_ACTIVE_USER_MODAL: 'subscriptionFreeAccountIosActiveUserModal',
  SUBSCRIPTION_FREE_ACCOUNT_IOS_ITUNES_ACTIVE_USER_MODAL: 'subscriptionFreeAccountIosItunesActiveUserModal',
  SUBSCRIPTION_FREE_ACCOUNT_IOS_RESTORE_TRANSACTION_FAILURE_MODAL: 'subscriptionFreeAccountIosRestoreTransactionFailureModal',
  SUBSCRIPTION_FREE_ACCOUNT_IOS_RESTORE_TRANSACTION_INITIAL_MODAL: 'subscriptionFreeAccountIosRestoreTransactionInitialModal',
  SUBSCRIPTION_HOLIDAY_OFFER_79D8E34: 'subscriptionHolidayOffer79d8e34',
  SUBSCRIPTION_HOLIDAY_OFFER_79D8E34_ACTIVE_USER_MODAL: 'subscriptionHolidayOffer79d8e34ActiveUserModal',
  SUBSCRIPTION_SEASONAL_OFFER_4D28DR6: 'subscriptionSeasonalOffer4d28dr6',
  SUBSCRIPTION_SEASONAL_OFFER_4D28DR6_ACTIVE_USER_MODAL: 'subscriptionSeasonalOffer4d28dr6ActiveUserModal',
  SUBSCRIPTION_SEASONAL_OFFER_AECC093: 'subscriptionSeasonalOfferaecc093',
  SUBSCRIPTION_SEASONAL_OFFER_AECC093_ACTIVE_USER_MODAL: 'subscriptionSeasonalOfferaecc093ActiveUserModal',
  SUBSCRIPTION_ABCMOUSE_743959D: 'subscriptionAbcmouseOffer743959D',
  SUBSCRIPTION_ABCMOUSE_743959D_ACTIVE_USER_MODAL: 'subscriptionAbcmouseOffer743959DActiveUserModal',
  SubscriptionAbcmouse743959dUserNotFoundForExistingProductModal:
  'SubscriptionAbcmouse743959dUserNotFoundForExistingProductModal',
  TERMINATE: 'terminate',
  UPGRADE: 'upgrade',
  UPGRADE_GROUPON: 'upgradeGroupon',
  UPGRADE_SCHOOL: 'upgradeSchool',
  UPGRADE_TEACHER: 'upgradeTeacher',
  UPGRADE_OFFER_MODAL: 'upgradeOfferModal',
  UPGRADE_TEACHER_MODAL: 'upgradeTeacherModal',
  UPGRADE_GROUPON_OFFER_MODAL: 'upgradeGrouponOfferModal',
  UPGRADE_SEASONAL_OFFER_AECC093: 'upgradeSeasonalOfferaecc093',
  UPGRADE_SEASONAL_OFFER_AECC093_OFFER_MODAL: 'upgradeSeasonalOfferaecc093OfferModal',
  UPGRADE_SCHOOL_OFFER_MODAL: 'upgradeSchoolOfferModal',
  RESET_PASSWORD: 'resetPassword',
  RESTORE_TRANSACTION: 'restoreTransaction',
  RESTORE_TRANSACTION_INACTIVE_INVALID_MODAL: 'restoreTransactionInactiveInvalidModal',
  RESTORE_TRANSACTION_INACTIVE_VALID_MODAL: 'restoreTransactionInactiveValidModal',
  REDEEM: 'redeem',
  REDEEM_CREATE_ACCOUNT: 'redeemCreateAccount',
  REDEEM_CREATE_USER: 'redeemCreateUser',
  REDEEM_EMAIL_IN_USE_MODAL: 'redeemEmailInUse',
  REDEEM_INVALID_DEACTIVATED_USER_MODAL: 'redeemInvalidDeactivatedUserModal',
  REDEEM_LOGIN_FORM: 'redeemLoginForm'
};

const usernameTypes = {
  EMAIL_ADDRESS: 'email'
};

export {
  adjustTokens,
  apis,
  apiFormatters,
  appCalls,
  appCallCallbacks,
  cacheNamespaces,
  cookies,
  countryCodes,
  createAccountFormTypes,
  environment,
  eventNames,
  localStorageKeys,
  newWorlds,
  redirectCountryCodes,
  cookieConsentKeys,
  cookieConsentValues,
  outboundLinks,
  pages,
  paymentTypes,
  pixelEvents,
  privacyPolicySections,
  products,
  queryParamKeys,
  queryParamValues,
  redeemCodeTypes,
  regStepsEnumerate,
  rotation,
  sdoNamespaces,
  usernameTypes
};
