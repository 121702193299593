/* eslint-disable */
const {cookieConsentKeys, cookieConsentValues} = require('../constants-enumerate');

if (localStorage.getItem(cookieConsentKeys.COOKIE_PREFERENCES) === null) {
  localStorage.setItem(cookieConsentKeys.COOKIE_PREFERENCES, cookieConsentValues.COOKIES_DEFAULT);
}

if (!navigator.globalPrivacyControl && localStorage.getItem(cookieConsentKeys.COOKIE_PREFERENCES) === cookieConsentValues.COOKIES_DEFAULT) {
  var googleAnalytics = document.createElement('script');
  googleAnalytics.src ='https://www.googletagmanager.com/gtag/js?id=UA-129044860-1';
  googleAnalytics.async = true;
  document.body.appendChild(googleAnalytics);

  window.dataLayer = window.dataLayer || [];
  function gtag1() {
    dataLayer.push(arguments);
  }
  gtag1('js', new Date());
  gtag1('config', 'UA-129044860-1');
  var gaInterval = setInterval(function() {
    if (window.ga) {
      window.ga('create', 'UA-129044860-1', 'auto');
      clearInterval(gaInterval);
    }
  }, 500);
}

if (!navigator.globalPrivacyControl && localStorage.getItem(cookieConsentKeys.COOKIE_PREFERENCES) === cookieConsentValues.COOKIES_DEFAULT) {
  var bingScript = document.createElement('script');
  bingScript.textContent = '(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"18002918"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");';
  document.body.appendChild(bingScript);

  var googleAds = document.createElement('script');
  googleAds.src ='https://www.googletagmanager.com/gtag/js?id=AW-782124098';
  googleAds.async = true;
  document.body.appendChild(googleAds);

  window.dataLayer = window.dataLayer || [];
  function gtag2(){dataLayer.push(arguments);}
  gtag2("js", new Date());

  gtag2("config", "AW-782124098");

  (function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({"projectId":"10000","properties":{"pixelId":"10067892"}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded")
  {return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");

  (function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({"projectId":"10000","properties":{"pixelId":"10067897"}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}
  try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p)
  {y([p])}
  ;y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");

  if(window.ga) {
    window.ga(function() {
      var puCheckWaitMsecs = 10000;	//10 secs
      window.setTimeout( function() {
        if(document.readyState === 'complete' && !window.DIGIOH_API) {
          console.log('POSTUP ERROR: not initialized');
          ga('send', {
            hitType: 'event',
            eventCategory: 'PostUp',
            eventAction: 'PostUp Inactive',
            eventLabel: puCheckWaitMsecs.toString(),
            nonInteraction: 1
          });
        }
      }, puCheckWaitMsecs);
    });
  }
}
